import {
  DresonRuleResolvedEvent,
  DresonRuleResolveEvent,
  HistoricDresonRuleResolveEvent,
} from "../../types/FilterTypes";
import { eventQBus } from "../../types/EventQBus";
import { replaceTrackingContext } from "../../tracking/TrackingAPI";
import { core } from "@otto-ec/global-resources/core";
import { breakpoint } from "@otto-ec/global-resources/breakpoint";
import { SfidWrapper } from "../../head/eventHistory/SfidWrapper";

export default class FilterResolveDresonRule {
  private static readonly URI: string = "/grasshopper/filter/resolve/dreson";

  /**
 *
 *
 *
 *
 */
  public static resolveFormData(event: DresonRuleResolveEvent | HistoricDresonRuleResolveEvent): void {
    const mustResolve = !event.historic && (<DresonRuleResolveEvent>event).formData;
    const { page, query, tsLink, shouldScroll } = mustResolve
      ? FilterResolveDresonRule.parseEventData(event as DresonRuleResolveEvent)
      : FilterResolveDresonRule.parseHistoricEventData(event as HistoricDresonRuleResolveEvent);

    if (FilterResolveDresonRule.isInvalidEvent(mustResolve, event, query)) {
      eventQBus.emit("ftfind.dresonRule.resolved", {
        ssid: SfidWrapper.ssid(),
        ...event,
      } as DresonRuleResolvedEvent);
      return;
    }

    const tracking = core.serialize({
      ts_Assert: "none",
      ...tsLink,
    }) as string;

    fetch(FilterResolveDresonRule.URI + `?${query}`, {
      headers: {
        "X-Find-Trackable": tracking,
      },
    })
      .then(FilterResolveDresonRule.extractResponse)
      .then(FilterResolveDresonRule.replaceSFID)
      .then(FilterResolveDresonRule.refreshTrackingContext)
      .then((avContentData: any) => {
        const { count, ...rest } = avContentData;
        eventQBus.emit("ftfind.dresonRule.resolved", {
          historic: event.historic,
          mergeOnPi: true,
          shouldScroll: shouldScroll,
          page,
          ssid: SfidWrapper.ssid(),
          ...rest,
          ...(parseInt(count) >= 0 ? { count: parseInt(count) } : {}),
        });
      })
      .catch(FilterResolveDresonRule.handleError);
  }

  private static isInvalidEvent(
    mustResolve: any,
    event: DresonRuleResolveEvent | HistoricDresonRuleResolveEvent,
    query: string | void
  ): boolean {
    let invalid = false;
    const invalidRules: string[] = ["rule=&", "rule=undefined", "rule=null"];

    if (!mustResolve && FilterResolveDresonRule.getCurrentRule() === (<HistoricDresonRuleResolveEvent>event).rule) {
      invalid = true;
    } else if (!query || invalidRules.some((rule) => query.includes(rule))) {
      invalid = true;
    }

    return invalid;
  }

  private static getCurrentRule() {
    const ruleDiv: HTMLElement | null = FilterResolveDresonRule.avContent();
    return ruleDiv ? ruleDiv.dataset.rule : undefined;
  }

  private static parseHistoricEventData(event: HistoricDresonRuleResolveEvent) {
    const { rule, page, tsLink, shouldScroll } = event;
    const query = (URLSearchParams && new URLSearchParams({ rule }).toString()) || core.serialize({ rule });
    return { page, query, tsLink, shouldScroll };
  }

  private static parseEventData(event: DresonRuleResolveEvent) {
    const { formData } = event;
    const sortOrder = <string>formData.get("sortiertnach");
    const shouldScroll = FilterResolveDresonRule.shouldScroll();
    /*         */
    const query = new URLSearchParams(formData).toString();
    return { page: { sortiertnach: sortOrder }, query, tsLink: {}, shouldScroll };
  }

  private static extractResponse(xhr: Response) {
    /*                                                               */
    if (xhr.status !== 200 && xhr.status !== 404) {
      throw Error(`Failed to resolve rule: ${xhr.status} ${xhr.statusText}`);
    }
    return xhr.text();
  }

  private static replaceSFID(response: string) {
    const sfidWrapper: HTMLElement | null = document.getElementById("reptile-sfid-wrapper");
    if (sfidWrapper && window.o_tracking) {
      sfidWrapper.innerHTML = response;
    }
  }

  private static refreshTrackingContext(): object {
    const element: HTMLElement | null = FilterResolveDresonRule.avContent();
    if (element) {
      const data = element.dataset;
      replaceTrackingContext("reptile-sfid-wrapper", data.redirect || "");
      return data;
    }
    return {};
  }

  private static avContent(): HTMLElement | null {
    return document.getElementById("avContent");
  }

  private static handleError(e: Error): void {
    /*                                                                                                */
    eventQBus.emit("ftfind.dresonRule.resolveAborted");
  }

  private static shouldScroll() {
    return breakpoint?.isSmall() || breakpoint?.isMedium();
  }
}
